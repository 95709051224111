<template>
    <div>
        <h1>Terminals</h1>
        <p>
            <router-link :to="'/terminal'">New</router-link>
        </p>
        <table class="table">
            <thead>
                <tr>
                    <th>
                        Name
                    </th>
                    <th>
                        Action
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-for="terminal  in terminals" :key="terminal.id">
                    <tr>

                        <td>
                            {{terminal.name}}
                        </td>

                        <td>
                            <router-link :to="'/terminal/' + terminal.id">View / Edit</router-link>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
    import terminalservice from '@/services/TerminalService'

    export default {
        name: 'TerminalListComponent',
        data() {
            return {
                terminals: [],
            }
        },
        methods: {
            getTerminals() {
                terminalservice.index().then(response => {
                    this.terminals = response.data
                }).catch(error => {
                    console.log('error', error) // create notification-toaster for user error
                })
            },
        },
        mounted() {
            this.getTerminals()
        }
    }
</script>