<template>
  <div>
    <TerminalListComponent/>
  </div>
</template>

<script>
  import TerminalListComponent from '@/components/terminal/TerminalListComponent.vue';

  export default {
    name: 'terminal',
    components: {
      TerminalListComponent
    }
  }
</script>